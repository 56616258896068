.childrenContainer {
  display: flex;
  padding-inline-start: 0;
  margin: 0;
  padding-top: var(--tree-line-height);
  position: relative;
}
.childrenContainer::before {
  content: '';
  position: absolute;
  top: 0;
  height: var(--tree-line-height);
  box-sizing: border-box;
  left: calc(50% - var(--tree-line-width) / 2);
  width: 0;
  border-left: var(--tree-line-width) var(--tree-node-line-style)
    var(--tree-line-color);
}
.node {
  flex: auto;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: var(--tree-line-height) var(--tree-node-padding) 0
    var(--tree-node-padding);
}

.one__parrent ul:last-child li {
  padding-top: 20px;
}
.one__parrent ul:last-child::before {
  height: 40px;
}
