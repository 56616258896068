.sidebar__profile .ant-menu .ant-menu-item,
.sidebar__profile .ant-menu .ant-menu-submenu {
  padding: 0;
  margin-right: 20px;
  /* padding-bottom: 15px; */
}
.sidebar__profile .ant-menu .ant-menu-submenu-title {
  padding: 0;
}
.sidebar__profile
  .ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after,
.sidebar__profile
  .ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-selected::after,
.sidebar__profile
  .ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu-selected::after,
.sidebar__profile
  .ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-selected::after {
  inset-inline: 0;
}
.sidebar__profile .ant-menu-light.ant-menu-horizontal > .ant-menu-item::after,
.sidebar__profile
  .ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item::after,
.sidebar__profile
  .ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu::after,
.sidebar__profile
  .ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu::after {
  inset-inline: 0;
}

.filter__profile {
  position: relative;
}

.filter__profile .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #fff;
  padding: 12px 0;
}

.filter__profile .ant-collapse {
  border: none;
}

.filter__profile .ant-collapse > .ant-collapse-item {
  border-bottom: 2px solid #3d5279;
}

.filter__profile .ant-collapse .ant-collapse-content {
  border: none;
}

.filter__profile .ant-collapse > .ant-collapse-item:last-child,
.filter__profile
  .ant-collapse
  > .ant-collapse-item:last-child
  > .ant-collapse-header {
  border-radius: 0;
  border: none;
}

.filter__profile
  .ant-collapse
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 12px 0;
}
