.card__monster_detail {
  background: url(../imgs/bgMonsterDetail.png) center center no-repeat;
  background-size: contain;
}

.card__monster_detail:hover .card__monster_img {
  transform: scale(1.05);
  transition: 0.5s;
}

.card__monster_detail:hover {
  box-shadow: inset 0 0 5px 2px #40a9ff;
  transition: 0.5s;
  border-radius: 10px;
}

.title__monster_detail {
  font-size: 24px;
  font-family: 400;
  text-shadow: 1px 0 5px #2ca0ffcc;
}
.title__monster_detail_name {
  font-size: 20px;
  font-family: 400;
  text-shadow: 1px 0 5px #2ca0ffcc;
}
.polygon__outside_sm {
  clip-path: polygon(
    20% 0%,
    80% 0%,
    100% 20%,
    100% 80%,
    80% 100%,
    20% 100%,
    0% 80%,
    0% 20%
  );
}

.polygon__inside_sm {
  position: absolute;
  top: 1.5px;
  left: 1.5px;
  right: 1.5px;
  bottom: 1.5px;
  background: #0d2758;
  clip-path: polygon(
    20% 0%,
    80% 0%,
    100% 20%,
    100% 80%,
    80% 100%,
    20% 100%,
    0% 80%,
    0% 20%
  );
}

.polygon__outside {
  clip-path: polygon(
    93% 100%,
    100% 77%,
    100% 22%,
    92% 0%,
    8% 0%,
    0% 24%,
    0% 76%,
    8% 100%
  );
}

.polygon__inside {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background: #0d2758;
  clip-path: polygon(
    93% 100%,
    100% 77%,
    100% 22%,
    92% 0%,
    8% 0%,
    0% 24%,
    0% 76%,
    8% 100%
  );
}

.polygon__outside_rank {
  clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0% 70%,
    0% 30%
  );
}
.polygon__inside_rank {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background: #0d2758;
  clip-path: polygon(
    10% 0%,
    90% 0%,
    100% 10%,
    100% 90%,
    90% 100%,
    10% 100%,
    0% 90%,
    0% 10%
  );
}
.polygon__inside_rankSkill {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  clip-path: polygon(
    10% 0%,
    90% 0%,
    100% 10%,
    100% 90%,
    90% 100%,
    10% 100%,
    0% 90%,
    0% 10%
  );
}
.polygon__outside_img {
  clip-path: polygon(
    5% 0%,
    95% 0%,
    100% 5%,
    100% 95%,
    95% 100%,
    5% 100%,
    0% 95%,
    0% 5%
  );
}
.polygon__inside_img {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background: #0d2758;
  clip-path: polygon(
    5% 0%,
    95% 0%,
    100% 5%,
    100% 95%,
    95% 100%,
    5% 100%,
    0% 95%,
    0% 5%
  );
}
.polygon__outside_imgSkill {
  clip-path: polygon(
    10% 0%,
    90% 0%,
    100% 10%,
    100% 90%,
    90% 100%,
    10% 100%,
    0% 90%,
    0% 10%
  );
}
.polygon__inside_imgSkill {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background: #0d2758;
  clip-path: polygon(
    10% 0%,
    90% 0%,
    100% 10%,
    100% 90%,
    90% 100%,
    10% 100%,
    0% 90%,
    0% 10%
  );
}
.common__btn_poloygon:hover .polygon__inside {
  background: #40a9ff;
}

.active__polygon {
  background: #40a9ff !important;
}

.hexagon__outside {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.hexagon__inside {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background: #0d2758;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.common__btn_seedtre:hover .polygon__inside {
  background: #4aaf4f !important;
  opacity: 0.9;
}

.active__seedtre {
  background: #4aaf4f !important;
}

.treeModal ul:first-child {
  transform: rotate(180deg);
}

.li:has(.no__parr) {
  background: red;
}

.modal__skill {
  color: #fff;
}

@media screen and (min-width: 768px) {
  .title__monster_detail {
    font-size: 32px;
  }
  .title__monster_detail_name {
    font-size: 24px;
  }
}
