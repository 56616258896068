.block__ground_shop {
  background: url(../imgs/banner/bgShop.png) center center no-repeat;
  background-size: cover;
}

.title__shop {
  text-align: center;
  margin-top: 100px;
  font-size: 30px;
  text-shadow: 1px 0 5px #fff;
  filter: drop-shadow(0 0 0.75rem #2ca0ffcc);
}
.title__shop_item {
  text-align: center;
  margin-top: 100px;
  font-size: 30px;
  text-shadow: 1px 0 5px #fff;
  filter: drop-shadow(0 0 0.75rem #2ca0ffcc);
}

.title__shop_card {
  filter: drop-shadow(0 0 0.75rem #2ca0ffcc);
  font-weight: 700;
  font-size: 18px;
}
.type__shop_card {
  font-weight: 700;
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .title__shop {
    font-size: 48px;
    margin-top: 2em;
  }
  .title__shop_item {
    font-size: 48px;
  }
}

@media screen and (min-width: 1280px) {
  .title__shop {
    margin-top: 0em;
  }
}
