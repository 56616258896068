.color-body {
  filter: brightness(0) saturate(100%) invert(93%) sepia(20%) saturate(5533%)
    hue-rotate(43deg) brightness(104%) contrast(94%);
}

.color-condition {
  filter: brightness(0) saturate(100%) invert(93%) sepia(20%) saturate(5533%)
    hue-rotate(-47deg) brightness(104%) contrast(94%);
}

.color-dislike {
  filter: brightness(0) saturate(100%) invert(68%) sepia(93%) saturate(3865%)
    hue-rotate(281deg) brightness(98%) contrast(95%);
}

.color-energy {
  filter: brightness(0) saturate(100%) invert(93%) sepia(20%) saturate(5533%)
    hue-rotate(363deg) brightness(104%) contrast(94%);
}

.color-fatigue {
  filter: brightness(0) saturate(100%) invert(30%) sepia(84%) saturate(1223%)
    hue-rotate(182deg) brightness(97%) contrast(101%);
}

.color-lifespan {
  filter: brightness(0) saturate(100%) invert(54%) sepia(71%) saturate(2123%)
    hue-rotate(178deg) brightness(96%) contrast(91%);
}
.color-friendship {
  filter: brightness(0) saturate(100%) invert(93%) sepia(20%) saturate(5533%)
    hue-rotate(-47deg) brightness(104%) contrast(94%);
}

.color-like,
.color-stress {
  filter: brightness(0) saturate(100%) invert(53%) sepia(89%) saturate(5640%)
    hue-rotate(695deg) brightness(103%) contrast(72%);
}

.color-AGI {
  filter: brightness(0) saturate(100%) invert(84%) sepia(21%) saturate(616%)
    hue-rotate(1deg) brightness(97%) contrast(125%);
}
.color-HP {
  filter: brightness(0) saturate(100%) invert(71%) sepia(18%) saturate(573%)
    hue-rotate(81deg) brightness(92%) contrast(160%);
}
.color-VIT {
  filter: brightness(0) saturate(100%) invert(57%) sepia(25%) saturate(323%)
    hue-rotate(77deg) brightness(91%) contrast(205%);
}
.color-INT {
  filter: brightness(0) saturate(100%) invert(62%) sepia(34%) saturate(5012%)
    hue-rotate(265deg) brightness(96%) contrast(94%);
}
.color-STR {
  filter: brightness(0) saturate(100%) invert(58%) sepia(74%) saturate(3831%)
    hue-rotate(326deg) brightness(98%) contrast(102%);
}
.color-DEX {
  filter: brightness(0) saturate(100%) invert(73%) sepia(28%) saturate(5070%)
    hue-rotate(177deg) brightness(102%) contrast(101%);
}
.color-rest {
  filter: brightness(0) saturate(100%) invert(73%) sepia(28%) saturate(5070%)
    hue-rotate(177deg) brightness(102%) contrast(101%);
}
