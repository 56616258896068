@keyframes common__btn_left {
  0% {
    transform: translatex(50%) rotate(180deg) scale(0.8);
    opacity: 1;
  }

  20% {
    transform: translatex(100%) rotate(180deg) scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translatex(1000%) rotate(180deg) scale(1);
  }
}

@keyframes common__btn_right {
  0% {
    transform: translatex(-50%) rotate(180deg) scale(0.8);
    opacity: 1;
  }

  20% {
    transform: translatex(-100%) rotate(180deg) scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translatex(-1000%) rotate(180deg) scale(1);
  }
}

@keyframes common___sub_btn_left {
  0% {
    transform: translatex(50%) rotate(180deg) scale(0.8);
    opacity: 1;
  }

  20% {
    transform: translatex(100%) rotate(180deg) scale(1.1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translatex(1000%) rotate(180deg) scale(1.3);
  }
}

@keyframes common__sub_btn_right {
  0% {
    transform: translatex(-50%) rotate(180deg) scale(0.8);
    opacity: 1;
  }

  20% {
    transform: translatex(-100%) rotate(180deg) scale(1.1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translatex(-1000%) rotate(180deg) scale(1.3);
  }
}


@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
